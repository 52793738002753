//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    components: { 
        DemoCascader: () => import('./modules/cascader'),
        DemoCheckbox: () => import('./modules/checkbox'),
        DemoDialog: () => import('./modules/dialog'),
        DemoEcharts: () => import('./modules/echarts'),
        DemoForm: () => import('./modules/form'),
        DemoFrame: () => import('./modules/frame'),
        DemoPagination: () => import('./modules/pagination'),
        DemoRadio: () => import('./modules/radio'),
        DemoSearch: () => import('./modules/search'),
        DemoSelect: () => import('./modules/select'),
        DemoTable: () => import('./modules/table'),
        DemoTree: () => import('./modules/tree'),
        DemoUpload: () => import('./modules/upload'),
        DemoPdfjs: () => import('./modules/pdf'),
        DemoUeditor: () => import('./modules/ueditor'),
        DemoWord: () => import('./modules/word'),
    },
    props: [],
    data () {
        return {
            
        }
    },
    methods: {
        
    },
    mounted: function() {
        
    }
}
